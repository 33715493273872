import React, { Component } from "react";

import QrReader from 'react-qr-reader'

import firebase from "../firebase";

import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Tooltip,
    IconButton,
    Hidden,
    Grid,
    Button,
    Divider,
    TextField,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import AuthProviderList from "./AuthProviderList";
import authentication from "../services/authentication"

import Compressor from "compressorjs"
import ImgQr from "../illustrations/qr.png"




const initialState = {
    performingAction: false,
    emailAddress: "",
    password: "",
    errors: null,
};

class ScanQr extends Component {
    constructor(props) {
        super(props);

        this.state = {
            performingAction: false,
            delay: 100,
            result: 'No result',
            startScan: false,
        };

        this.handleScan = this.handleScan.bind(this)
    }

    handleScan(data) {
        if (data) {
            // data.text es el texto del codigo qr
            if (data.includes('https://user-shotify.web.app') || data.includes('https://shot.muchpic.com')) {
                this.setState({
                    startScan: false
                }, () => {
                    window.location.href = data
                    const session = (window.location.hash).replace("#", "");
                    // guardo la sesion en el local storage
                    window.localStorage.session = session
                })
            } else {
                console.log('codigo invalido: ', data);
            }
        }

    }
    handleError(err) {
        console.error(err)
        this.setState({ startScan: false })
    }

    render() {
        const previewStyle = {
            height: '300px',
            with: '300px'
        }
        return (

            <Box
                style={{ transform: "translate(-50%, -50%)" }}
                position="absolute"
                top="50%"
                left="50%"
                textAlign="center"
            >


                {this.state.startScan ?
                    <div style={{ width: '100vw' }}>
                        <div style={{ position: 'absolute', right: 0, margin: 10, color: '#ffffffbf', zIndex: 999 }} onClick={() => {
                            this.setState({ startScan: false })
                        }}>
                            <CloseIcon style={{ width: 30, height: 30 }} ></CloseIcon>
                        </div>
                      
                        <QrReader
                            delay={300}
                            onError={(err) => this.handleError(err)}
                            onScan={(data) => this.handleScan(data)}
                            style={{ width: '100%' }}
                            showViewFinder={false}
                        />
                    </div>
                    :
                    <div style={{ fontSize: '500%', borderRadius: 10 }} onClick={() => {
                        console.log('Ya le hice click');
                        this.setState({ startScan: true })
                    }}>
                        <Typography variant="subtitle1" style={{ fontFamily: 'Titan one', color: "#777" }}>Escanea el codigo de la pantalla</Typography>
                        <img src={ImgQr} style={{ maxWidth: 250, minWidth: 120, width: '60%' }}></img>
                    </div>
                }



                <input hidden type="file" id="myfile" accept="image/*" placeholder="" onChange={(e) => {
                    const file = e.target.files[0];
                    if (!file) {
                        return;
                    }

                }}></input>
            </Box>

        );
    }
}



export default ScanQr;
