import React, { Component } from "react";



import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Tooltip,
    IconButton,
    Hidden,
    Grid,
    Button,
    Divider,
    TextField,
    CircularProgress
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import AuthProviderList from "./AuthProviderList";
import authentication from "../services/authentication"




const initialState = {
    performingAction: false,
    emailAddress: "",
    password: "",
    errors: null,
};

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            performingAction: false,
            loading: false
        };
    }
    signInWithAuthProvider = (provider) => {
        this.setState(
            {
                performingAction: true,
                loading: true
            },
            () => {
                authentication
                    .signInWithAuthProvider(provider)
                    .then((user) => {
                        // Me devuelve los datos del usuario
                        console.log('USUARIO:' + user.displayName + ' MAIL:' + user.email);

                    })
                    .catch((reason) => {
                        const code = reason.code;
                        const message = reason.message;
                        this.setState({
                            loading: false
                        });
                        switch (code) {
                            case "auth/account-exists-with-different-credential":
                            case "auth/auth-domain-config-required":
                            case "auth/cancelled-popup-request":
                            case "auth/operation-not-allowed":
                            case "auth/operation-not-supported-in-this-environment":
                            case "auth/popup-blocked":
                            case "auth/popup-closed-by-user":
                            case "auth/unauthorized-domain":
                                console.log(message);
                                return;

                            default:
                                console.log(message);
                                return;
                        }
                    })
                    .finally(() => {
                        this.setState({
                            performingAction: false,
                            loading: false
                        });
                    });
            }
        );
    };
    componentDidMount = () => {
        window.resultLoading = (valor) => {
            this.setState({ loading: valor })
        }
    }
    render() {

        return (
            <>
                {this.state.loading ?

                    <div style={{
                        width: '100%', height: '100%', backgroundColor: '#fff',
                        position: 'absolute',
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress></CircularProgress>
                    </div>
                    :
                    <div style={{ display: 'none' }}></div>
                }
                <Box
                    style={{ transform: "translate(-50%, -50%)", width: 'max-content' }}
                    position="absolute"
                    top="50%"
                    left="50%"
                    textAlign="center"
                >


                    <Grid container direction="row">

                        <Grid item xs={12}>
                            <AuthProviderList
                                performingAction={this.state.performingAction}
                                onAuthProviderClick={this.signInWithAuthProvider}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    }
}



export default LoginPage;
