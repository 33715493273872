import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import { Box, ButtonGroup, Button } from "@material-ui/core";

import authProviders from "../../data/auth-providers";

class AuthProviderList extends Component {
  render() {
    // Properties
    const { gutterBottom, performingAction } = this.props;

    // Events
    const { onAuthProviderClick } = this.props;

    return (
      <Box mb={gutterBottom ? 3 : 0}>
        <ButtonGroup
          disabled={performingAction}
          fullWidth
          orientation="vertical"
          variant="outlined"
        >

          {authProviders.map((authProvider) => {
            // const AuthProviderButton = withStyles({
            //   root: {
            //     color: authProvider.color,
            //   },
            // })(Button);
            <div key={authProvider.id} onClick={() => onAuthProviderClick(authProvider)}>
              <img src={authProvider.img}></img>
            </div>


            return (
              // <AuthProviderButton
              //   // key={authProvider.id}
              //   // startIcon={authProvider.icon}
              //   // onClick={() => onAuthProviderClick(authProvider)}
              // >
              //   {/* {authProvider.name} */}
              // </AuthProviderButton>
              // <div key={authProvider.id} onClick={() => onAuthProviderClick(authProvider)}>
              //   <img src={authProvider.img}></img>
              <div key={authProvider.id} style={{
                marginTop: authProvider.margin
              }}>
                <Button variant="contained" onClick={() => onAuthProviderClick(authProvider)} style={{
                  backgroundColor: authProvider.backgroundColor,
                  color: authProvider.color,
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: 15,
                  width: '100%',
                  justifyContent: 'flex-start',
                }} startIcon={<img style={{ width: 35, margin:'5px 10px' }} src={authProvider.iconPng}></img>}>
                  {authProvider.text}
                </Button>
              </div>
            );
          })}
        </ButtonGroup>
      </Box>
    );
  }
}

AuthProviderList.defaultProps = {
  gutterBottom: false,
  performingAction: false,
};

AuthProviderList.propTypes = {
  // Properties
  gutterBottom: PropTypes.bool,
  performingAction: PropTypes.bool,

  // Events
  onAuthProviderClick: PropTypes.func.isRequired,
};

export default AuthProviderList;
