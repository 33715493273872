import React, { Component } from "react";

import firebase from "../firebase";
import Snackbar from '@material-ui/core/Snackbar';

import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Tooltip,
    IconButton,
    Hidden,
    Grid,
    Button,
    Divider,
    TextField,

} from "@material-ui/core";

import Popover from '@mui/material/Popover';

import { Close as CloseIcon, RemoveShoppingCartSharp } from "@material-ui/icons";

import AuthProviderList from "./AuthProviderList";
import authentication from "../services/authentication"

import Compressor from "compressorjs"
import ImgCamara from "../illustrations/299066_camera_icon.png"
import ImgCamaraDisabled from "../illustrations/disableCamera.png"
import PhotoIcon from "../illustrations/photoIcon.png"
import random1 from "../illustrations/random1.png"
import random2 from "../illustrations/random2.png"
import random3 from "../illustrations/random3.png"
import random4 from "../illustrations/random4.png"
import random5 from "../illustrations/random5.png"
import random6 from "../illustrations/random6.png"
import random7 from "../illustrations/random7.png"
import { Alert } from "mdi-material-ui";





const initialState = {
    performingAction: false,
    emailAddress: "",
    password: "",
    errors: null,
};

class UserPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            performingAction: false,
            shareContent: false
        };
        this.ref = React.createRef();
        this.randomArr = [random1, random2, random3, random4, random5, random6, random7]
        this.randomArrPosition = 0
    }

    sendImg(img) {
        // obtengo el identificador de la sesion a donde pertenese la imagen
        const session = (window.location.hash).replace("#", "");
        // genero una key para la imagen 
        const key = firebase.database().ref().push().key;
        // guardo la imagen en firebase storage 
        firebase.storage().ref('images/' + session + '/' + key).put(img).then((x) => {
            x.ref.getDownloadURL().then((downloadURL) => {
                // ya guarde la foto y cuento con su url 
                // guardo la url en la base de datos 
                let objPost = {}
                objPost.t = Date.now()
                objPost.i = downloadURL
                firebase.database().ref('posts/' + session + '/' + key).set(objPost)
            })
        }).catch((error) => {
            console.log(error);
        })
    }

    createRandomImage(x) {
        const random = this.randomArr[x]
        const randomX = Math.floor(Math.random() * ((window.innerWidth - 70) - 0 + 1) + 0)
        const id = '_' + Date.now()
        const duration = Math.floor(Math.random() * (8 - 4 + 1) + 4)
        // const randomOpacity = Math.floor(Math.random() * (10 - 5 + 1) + 5) / 10
        console.log(randomX, id, duration)
        if (document.getElementById('content')) {
            document.getElementById('content').insertAdjacentHTML('beforeEnd', `<img id="${id}" style="animation-duration:${duration}s; left:${randomX}px" class="randomImage" src="${random}">`)
        }
        setTimeout(() => {
            if (document.getElementById(id)) {
                document.getElementById(id).remove()
            }
        }, duration * 1000)



    }

    componentDidMount() {
        const session = (window.location.hash).replace("#", "");
        firebase.database().ref('sessions/' + session + '/b/aus').once('value', (value1) => {
            if (value1.val() == true) {
                // se puede publicar, todo bien 
            } else {
                // no se puede publicar 
                this.setState({ shareContent: false })
            }
            firebase.database().ref('sessions/' + session + '/b/aus').on('value', (value2) => {
                // cambio el valor de publicacion 
                if (value2.val() == true) {
                    this.setState({ shareContent: true })
                } else if (value2.val() == false) {
                    this.setState({ shareContent: false })
                }

            })
        })

        const IntervalRandomImg = setInterval(() => {
            if (window.localStorage.session || window.location.hash) {
                const random = this.randomArrPosition
                this.createRandomImage(random)
                this.randomArrPosition++
                if (this.randomArrPosition == this.randomArr.length) {
                    this.randomArrPosition = 0
                }
            } else {
                console.log('LO FRENO NO TENFO LO QUE QUIERO');
                clearInterval(IntervalRandomImg);
            }
        }, 900)
    }

    sendImgAnimation() {
        console.log('se ejecuto sendImgAnimation');
        document.getElementById('PhotoIcon').style.animation = "MoveUp2";

        document.getElementById('PhotoIcon').style.animationDuration = '5s';
        document.getElementById('myfile').value = '';

        setTimeout(() => {
            document.getElementById('PhotoIcon').style.animation = '';
            document.getElementById('PhotoIcon').style.animationDuration = '';
        }, 5000);

    }

    render() {

        return (
            <div id="content" style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row'
            }}>


                {/* <Box
                    ref={this.ref}
                    style={{ transform: "translate(-50%, -50%)", zIndex: 100 }}
                    position="absolute"
                    top="50%"
                    left="50%"
                    textAlign="center"
                > */}
                {/* <div style={{ fontSize: '500%', borderRadius: 10 }} onClick={() => {
                        document.getElementById('myfile').click()
                    }}> */}
                {
                    this.state.shareContent ?
                        <img id="CameraIcon" class='photo' src={ImgCamara} style={{
                            width: 120, height: 120,
                            // bottom: -60, left: -60, 
                            position: 'absolute',
                            marginBottom: 0,
                            zIndex: 100
                        }} onClick={() => {
                            document.getElementById('myfile').click()
                        }}></img>
                        :
                        <img id="CameraDisabled" src={ImgCamaraDisabled} style={{
                            width: 120, height: 120,
                            // bottom: -60, left: -60, 
                            position: 'absolute',
                            marginBottom: 0,
                            zIndex: 100
                        }} onClick={(event) => {
                            this.setState({ anchorElPopover: event.currentTarget, openPopover: true }, () => {
                                setTimeout(() => {
                                    this.setState({ openPopover: false })
                                }, 1000);
                            })

                        }}></img>

                }

                {/* <Popover
                    style={{ padding: 5 }}
                    open={this.state.openPopover}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    anchorEl={this.state.anchorElPopover}
                // onClose={() => {
                //     this.setState({ openPopover: false })
                // }}

                >
                    No se puede compartir contenido
                </Popover> */}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    open={this.state.openPopover}
                    onClose={()=>{
                        this.setState({ openPopover: false })
                    }}
                    message="No se puede compartir contenido"
                    key={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                />

                <img id="PhotoIcon" src={PhotoIcon} style={{
                    width: 50, height: 50,
                    // bottom: -53, left: -45, 
                    // position: 'absolute', 
                    marginBottom: 0,
                    zIndex: 98
                }}></img>
                {/* </div> */}
                <form id="form1">
                    <input hidden type="file" id="myfile" accept="image/*" placeholder="" onChange={(e) => {
                        const file = e.target.files[0];
                        if (!file) {
                            // no pasa nada, muestro el icono de la camara
                            document.getElementById('CameraIcon').style.display = " "
                            return;
                        }
                        console.log('ejecuto la animacion de envio de imagen');
                        // ejecuto la animacion de envio de imagen
                        this.sendImgAnimation()
                        const sendImgFunt = (img) => {
                            this.sendImg(img)
                        }
                        new Compressor(file, {
                            quality: 0.6,
                            maxHeight: 1024,
                            maxWidth: 1024,
                            success(result) {
                                sendImgFunt(result)
                            },
                            error(err) {
                                console.log(err.message);
                            },
                        });
                    }}></input>
                </form>
                {/* </Box> */}

            </div >
        );
    }
}



export default UserPage;
