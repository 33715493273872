import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import firebase from "../../firebase"
import { auth } from "../../firebase";

import authentication from "../../services/authentication";

import EmptyState from "../EmptyState";

import UserCard from "../UserCard";
import UserPage from "../UserPage";
import ScanQr from "../scanQr";
import LoginPage from "../LoginPage";

import { ReactComponent as CabinIllustration } from "../../illustrations/cabin.svg";
import { ReactComponent as InsertBlockIllustration } from "../../illustrations/insert-block.svg";

class HomePage extends Component {
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };
  componentDidMount = () => {

    // obtengo el identificador de la sesion a donde pertenese la imagen
    if (window.location.hash) {
      console.log('tiene un HASH');
      const session = (window.location.hash).replace("#", "");
      // guardo la sesion en el local storage
      window.localStorage.session = session
      console.log('guardo el hash en el LOCAL STORAGE:', window.localStorage.session);
      firebase.database().ref('sessions/' + session + "/fa").once('value', (faSessions) => {
        if (faSessions.val() && (faSessions.val() < Date.now())) {
          console.log('el evento ya vencio');
          // el evento ya vencio 
          window.location.hash = ''
        } else {
          console.log('el envento esta en curso, tiene que subir fotos');
          // el envento esta en curso, tiene que subir fotos 
        }
      })
    } else {
      console.log('no tiene HASH');
      if (window.localStorage.session) {
        const session = window.localStorage.session
        console.log('tengo una sesion guardada en el LOCAL storage');
        firebase.database().ref('sessions/' + session + "/fa").once('value', (faSessions) => {
          if (faSessions.val() && (faSessions.val() < Date.now())) {
            console.log('el evento ya vencio');
            // el evento ya vencio 
            window.location.hash = ''
          } else {
            console.log('el envento esta en curso, tiene que subir fotos');
            // el envento esta en curso, tiene que subir fotos 
            window.location.hash = session
          }
        })
      } else {
        console.log('no tiene LOCAL STORAGE SESSION');
      }

    }

  }
  render() {
    const { user } = this.props;
    console.log('estoy en el render de HOME');
    if (user && window.location.hash) {
      return (
        <UserPage ></UserPage>
      );
    } else if (user) {
      return (
        <ScanQr></ScanQr>
      )
    } else {
      return (

        <LoginPage
          // image={<CabinIllustration />}
          title="Iniciar sesión con tu cuenta"
          description=""
          listLogins={true}
        />
      );
    }
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);
